import React, { useState, useEffect } from 'react';
import {
  MDBContainer,
  MDBInput,
  MDBCheckbox,
  MDBBtn,
  MDBIcon
}
  from 'mdb-react-ui-kit';
import * as sign from 'jwt-encode';
import jwt_decode from "jwt-decode";
import Cookies from 'universal-cookie';
import { Buffer } from 'buffer';

function App() {
  const cookies = new Cookies();
  const [data, setData] = useState(null);
  var setuser = function (result) {
    const secret = 'secret';//this is the secure key between mapmyaccess and k-hub used to decode and encode the token;
    var user = {
      Id: result.user._id,
      UserName: result.user.UserName,
      DisplayName: result.user.DisplayName,
      Email: result.user.Email,
      LibraryId: result.user.Library._id,
    }

    const jwt = sign({ user }, secret, {
      expiresIn: "1d", // expires in 1 day
    });
    // const options = {
    //   domain: 'demo.k-hub.in',
    //   path: "/", maxAge: 3600000, secure: true, HttpOnly: true
    // };

    cookies.set('MapMyAccess-auth-token', jwt);
    cookies.set('MapMyAccess-user', Buffer.from(JSON.stringify(user)).toString("base64"));
    setData(user)
  }


  var login = function () {
    fetch('https://api.mapmyaccess.com/auth/proxy/login', {
      method: "POST",

      // Adding body or contents to send
      body: JSON.stringify({
        Password: "info@123",
        UserName: "mapuser",
        Library: '61d7e9dc7969454f3a224fda'
      }),

      // Adding headers to the request
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
      .then(response => response.json())
      .then(json => setuser(json))
      .catch(error => console.error(error));
  }

  useEffect(() => {
    const cookies = new Cookies();

    if (cookies.get('MapMyAccess-auth-token')) {

      var decoded = jwt_decode(cookies.get('MapMyAccess-auth-token'));

      if (decoded.user) {
        setData(decoded.user);
      }
    }
  }, []);

  function Afterlogin() {
    return (
      <>

        <div class="d-flex flex-column align-items-center justify-content-center" style={{ height: '100vh' }}>
          <div>Welcome to Mapmyaccess Dashboard</div>
          <div>LogedIn as {data.DisplayName}</div>
          <button type="button" class="btn btn-primary" onClick={() => { cookies.remove('MapMyAccess-auth-token', { path: "/", domain: ".k-hub.in" }); cookies.remove('MapMyAccess-user', { path: "/", domain: ".k-hub.in" }); setData(null) }}>LogOut</button>
          <br />
          <h3>Proxy URLS </h3>
          <ul class='text-center p-0'>
            <li><a href='https://link-springer-com.kksu.demo.k-hub.in/' target="_blank">Springer</a></li>
            <li><a href='https://www-jstor-org.kksu.demo.k-hub.in/' target="_blank">Jstor</a></li>
            <li><a href='https://ieeexplore-ieee-org.kksu.demo.k-hub.in/Xplore/home.jsp' target="_blank">IEEE</a></li>
            <li><a href='https://www-tandfonline-com.kksu.demo.k-hub.in/journals/rfdj20' target="_blank">Taylor And Francis</a></li>
          </ul>
        </div>
      </>


    )
  }

  function Beforelogin() {
    return (
      <MDBContainer className="p-3 my-5 d-flex flex-column w-50">

        <MDBInput wrapperClass='mb-4' label='Email address' id='form1' type='email' value='mapuser' />
        <MDBInput wrapperClass='mb-4' label='Password' id='form2' type='password' value='info@123' />

        <div className="d-flex justify-content-between mx-3 mb-4">
          <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Remember me' />
          <a href="!#">Forgot password?</a>
        </div>

        <MDBBtn className="mb-4" onClick={() => { login() }}>Sign in</MDBBtn>

        <div className="text-center">
          <p>Not a member? <a href="#!">Register</a></p>
          <p>or sign up with:</p>

          <div className='d-flex justify-content-between mx-auto' style={{ width: '40%' }}>
            <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#1266f1' }}>
              <MDBIcon fab icon='facebook-f' size="sm" />
            </MDBBtn>

            <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#1266f1' }}>
              <MDBIcon fab icon='twitter' size="sm" />
            </MDBBtn>

            <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#1266f1' }}>
              <MDBIcon fab icon='google' size="sm" />
            </MDBBtn>

            <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#1266f1' }}>
              <MDBIcon fab icon='github' size="sm" />
            </MDBBtn>

          </div>
        </div>

      </MDBContainer>
    );
  }


  return (
    <>
      {data && data.DisplayName && (
        <Afterlogin />
      )}
      {!data && (
        <Beforelogin />
      )}


    </>
  )


}

export default App;